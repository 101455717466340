import React from "react";
import ZakatGiftAidV1 from "./giftAidV1";
import ZakatGiftAidV2 from "./giftAidV2";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";

const ZakatGiftAidPage = () => {
  return isZakat2025Enabled ? <ZakatGiftAidV2 /> : <ZakatGiftAidV1 />;
};

export default ZakatGiftAidPage;
